import React from "react"
import Layout from "../components/layout.component"
import Header from "../components/header.component"
import { graphql } from "gatsby"
import { linkFragment } from "../link-resolver"
import { isDark } from "../utils/styles"
import { Slices } from "../components/slices.component"
import { Section } from "../components/common/section.component"
import { AsideNavigation } from "../components/common/aside-navigation.component"
import StickyBox from "react-sticky-box"
import { RichText } from "../components/common/rich-text.component"
import SEO from "../components/common/seo.component"

const ContentPage = ({ data }) => {
  const page = data.prismic.contentPage

  if (page) {
    const dark = isDark(page.background_color, page.background_image)

    const { aside_navigation } = page
    return (
      <Layout>
        <SEO
          title={page.page_title || page.title}
          description={page.description || page.text}
          keywords={page.keywords}
          image={page.preview_image}
          lang={page._meta.lang}
        />
        <Section
          className="text-center"
          backgroundImage={page.background_image}
          backgroundColor={page.background_color}
        >
          <Header theme={dark ? "dark" : "light"} />
          <div className="content-page-title mb-3">
            <RichText render={page.title} />
          </div>
          <div className="content-page-text">
            <RichText render={page.text} />
          </div>
        </Section>
        {aside_navigation ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-mb-12 text-lg-left text-center">
                <StickyBox offsetTop={80} offsetBottom={20}>
                  <AsideNavigation navigation={aside_navigation} />
                </StickyBox>
              </div>
              <div className="col-lg-8 col-mb-12">
                <Slices body={page.body} />
              </div>
            </div>
          </div>
        ) : (
          <Slices body={page.body} />
        )}
      </Layout>
    )
  }
  return null
}
ContentPage.fragments = [linkFragment]

export default ContentPage

export const query = graphql`
  query contentPageQuery($uid: String!, $lang: String!) {
    prismic {
      contentPage(uid: $uid, lang: $lang) {
        __typename
        page_title
        description
        keywords {
          keyword
        }
        preview_image
        _meta {
          uid
          type
          lang
        }
        background_image
        background_color
        text
        title
        aside_navigation {
          ... on PRISMIC_AsideNavigation {
            _meta {
              lang
              uid
            }
            text
            body {
              ... on PRISMIC_AsideNavigationBodyMenu {
                label
                type
                primary {
                  nav_link {
                    ...link
                  }
                  nav_text
                }
                fields {
                  icon
                  link {
                    ...link
                  }
                  link_style
                  link_text
                }
              }
            }
          }
        }
        parent {
          ... on PRISMIC_ContentPage {
            _meta {
              lang
              type
              uid
            }
            parent {
              ... on PRISMIC_ContentPage {
                _meta {
                  lang
                  type
                  uid
                }
              }
            }
          }
        }
        body {
            ... on PRISMIC_ContentPageBodyGallery {
                type
                label
                primary {
                    anchor
                    bgColor
                    bgImage
                    text
                    title
                }
                fields {
                    image
                    text
                    title
                }
            }
          ... on PRISMIC_ContentPageBodyItems_collection {
            label
            type
            primary {
              bg_color
              bg_image
              text
              title
              link_style
              link_text
            }
            fields {
              tag
            }
          }
          ... on PRISMIC_ContentPageBodyPricing_plans {
            type
            label
            fields {
              price_units
              plan_price
              plan_name
              plan_features
              link_text
              link_style
              link {
                ...link
              }
              is_free_plan
            }
            primary {
              bg_image
              bg_color
              title
              text
            }
          }
          ... on PRISMIC_ContentPageBodyText {
            type
            label
            primary {
              text
              bg_color
              bg_image
            }
          }
          ... on PRISMIC_ContentPageBodyFeature {
            type
            label
            primary {
              bg_color
              bg_image
              title
              text
            }
            fields {
              image
              link_style
              link_text
              text
              title
              link {
                ...link
              }
            }
          }
          ... on PRISMIC_ContentPageBodyBlock_with_text_and_image {
            label
            type
            primary {
              bg_color
              bg_image
              min_height
              title
              text
              image
              link {
                ...link
              }
              link_style
              link_text
            }
          }
          ... on PRISMIC_ContentPageBodyForm {
            type
            label
            primary {
              bg_color
              bg_image
              form_script
              form_url
              text_content
            }
          }
        }
      }
    }
  }
`
